<template>
  <div class="contratpdf" ref="contratpdf" id="contratpdf" style="font-size:1em !important;">
    <section style="padding-bottom:200px;" class="content pdf-item" slot="pdf-content">

      <section class="">
        <div class="card" style="width:100%;">
          <div class="card-body">
            <div style="margin-top:10px; margin-bottom:20px;">
              <v-row>
                <v-col style="padding-top:20px;">
                  <img height="110" src="../../public/logogrand.png">
                </v-col>
                <v-col>
                  <h4>Contract # {{ parent_data.contract_number }}</h4>
                  <h4>{{ parent_data.lastname }} {{ parent_data.firstname }}</h4>
                  <h4>{{ parent_data.signature_date }}</h4>
                  <v-btn small @click="print" class="disappearmedia mr-2">Print</v-btn>
                  <v-btn small @click="pdf" class="disappearmedia mr-2">PDF</v-btn>
                  <v-btn small @click="share" class="disappearmedia">Share</v-btn>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
        <div class="card" style="width:100%;">
          <div class="card-body">
            <h5 class="card-title text-uppercase font-weight-bold">Vehicle details</h5>
            <v-row>
              <v-col class="text-uppercase font-weight-bold">Plate ID / Brand / Model / Frame</v-col>
              <v-col>{{ parent_data.plate }} / {{ parent_data.brand }} / {{ parent_data.model }} / {{
                parent_data.chassis
              }}</v-col>
            </v-row>
            <v-row>
              <v-col class="text-uppercase font-weight-bold">Mileage</v-col>
              <v-col>{{ parent_data.car_km }} KM</v-col>
              <v-col class="text-uppercase font-weight-bold">Gas (%)</v-col>
              <v-col>{{ Math.max(0, parent_data.vehicle_full_percent) }} %</v-col>
            </v-row>
            <v-row>
              <v-col class="text-uppercase font-weight-bold">State of the vehicle before rental</v-col>
            </v-row>
            <table style="margin-top:15px;max-width:100%;" class="table" v-for="(damage, index) in parent_data.damages"
              v-bind:key="index">
              <tr>
                <td style="max-width:80%;">
                  <div class="mb-0">
                    <img style="max-width:100%;max-height:100%;"
                      :src='parent_data.damage_images["id_damage_images_" + index]'>
                  </div>
                </td>
                <td>
                  {{ damage.text }}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </section>
      <section style="" class="">
        <div class="card" style="width:100%;">
          <div class="card-body">
            <h5 class="text-uppercase font-weight-bold card-title">Vehicle driver</h5>
            <v-row>
              <v-col cols="2" class="text-uppercase font-weight-bold">First name</v-col>
              <v-col cols="2">{{ parent_data.firstname }}</v-col>
              <v-col cols="2" class="text-uppercase font-weight-bold">Last name</v-col>
              <v-col cols="2">{{ parent_data.lastname }}</v-col>
              <v-col cols="2" class="text-uppercase font-weight-bold">Birth</v-col>
              <v-col cols="2">{{ parent_data.birthdate }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="2" class="text-uppercase font-weight-bold">Address</v-col>
              <v-col cols="2">{{ parent_data.address }}</v-col>
              <v-col cols="2" class="text-uppercase font-weight-bold">City</v-col>
              <v-col cols="2">{{ parent_data.npa }} {{ parent_data.city }}</v-col>
              <v-col cols="2" class="text-uppercase font-weight-bold">Country</v-col>
              <v-col cols="2">{{ parent_data.country }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="2" class="text-uppercase font-weight-bold">Telephone</v-col>
              <v-col cols="2">{{ parent_data.telephone }}</v-col>
              <v-col cols="2" class="text-uppercase font-weight-bold">Email</v-col>
              <v-col cols="2">{{ parent_data.email }}</v-col>
              <v-col cols="2" class="text-uppercase font-weight-bold">Licence</v-col>
              <v-col cols="2">{{ parent_data.permit_id }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="2" class="text-uppercase font-weight-bold">ID Type</v-col>
              <v-col cols="2">{{ parent_data.id_type }}</v-col>
              <v-col cols="2" class="text-uppercase font-weight-bold">ID Number</v-col>
              <v-col cols="2">{{ parent_data.idnumber }}</v-col>
              <v-col cols="2" class="text-uppercase font-weight-bold">Exp.</v-col>
              <v-col cols="2">{{ parent_data.expiration }}</v-col>
            </v-row>
            <v-row style="margin-bottom:10px;">
              <v-col class="text-uppercase font-weight-bold">ID pictures</v-col>
            </v-row>
            <div v-for="(image, index) in parent_data.id_images" v-bind:key="index" class="mb-16">
              <div style="height:300px;max-width:100%">
                <img style="max-width:100%;max-height:100%;" :src='parent_data.id_images[index]'>
              </div>
            </div>
          </div>
        </div>
        <div class="card pb" style="width:100%;">
          <div class="card-body">
            <h5 class="text-uppercase font-weight-bold card-title">Vehicle after rental</h5>
            <v-row>
              <v-col class="text-uppercase font-weight-bold">Mileage</v-col>
              <v-col>{{ parent_data.after_total_km }} KM (extra KM: {{ parent_data.after_extra_km }})</v-col>
              <v-col class="text-uppercase font-weight-bold">Gas</v-col>
              <v-col>{{ Math.max(0, parent_data.vehicle_full_percent) }} %</v-col>
            </v-row>
            <v-row>
              <v-col class="text-uppercase font-weight-bold">Vehicle damage</v-col>
            </v-row>
            <v-row>
              <v-col><img width="300" src="../../public/vehicle_damage.jpg"></v-col>
              <v-col class="text-uppercase font-weight-bold">Remarks: {{ parent_data.after_remarks }}</v-col>
            </v-row>
          </div>
        </div>
        <div class="card" style="width:100%;padding-bottom:20px;">
          <div class="card-body">
            <h5 class="text-uppercase font-weight-bold card-title">Rental details</h5>
            <v-row>
              <v-col class="text-uppercase font-weight-bold">From: </v-col>
              <v-col>Date: {{ parent_data.date_start }} {{ parent_data.time_start }}</v-col>
              <!--<v-col>Location: {{ parent_data.place_start }}</v-col> -->
            </v-row>
            <v-row>
              <v-col class="text-uppercase font-weight-bold">To: </v-col>
              <v-col>Date: {{ parent_data.date_end }} {{ parent_data.time_stop }}</v-col>
              <!--<v-col>Location: {{ parent_data.place_stop }}</v-col> -->
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col>
                <h6 class="text-uppercase font-weight-bold">Before rental</h6>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-uppercase font-weight-bold">Price/day</v-col>
              <v-col>{{ parent_data.price_per_day }} {{ parent_data.currency }}</v-col>
              <v-col class="text-uppercase font-weight-bold">Price/extra KM</v-col>
              <v-col>{{ parent_data.price_extra_km }} {{ parent_data.currency }}</v-col>
            </v-row>
            <v-row>
              <v-col class="text-uppercase font-weight-bold">Qty included KM / day</v-col>
              <v-col>{{ parent_data.included_km }} km</v-col>
              <v-col class="text-uppercase font-weight-bold">Qty included extra KM</v-col>
              <v-col>{{ parent_data.extra_km }} km</v-col>
            </v-row>
            <v-row>
              <v-col class="text-uppercase font-weight-bold">Delivery cost</v-col>
              <v-col>{{ parent_data.delivery }} {{ parent_data.currency }}</v-col>
              <v-col class="text-uppercase font-weight-bold">Extra costs</v-col>
              <v-col>{{ parent_data.extra_costs }} {{ parent_data.currency }}</v-col>
            </v-row>
            <v-row>
              <v-col class="text-uppercase font-weight-bold">VAT (%)</v-col>
              <v-col>{{ parent_data.vat }} %</v-col>
              <v-col class="text-uppercase font-weight-bold">PAYMENT FEES (%)</v-col>
              <v-col>{{ parent_data.fees }} %</v-col>
            </v-row>
            <v-row>
              <v-col class="text-uppercase font-weight-bold">Total before rental</v-col>
              <v-col>{{ parent_data.subtotal }} {{ parent_data.currency }}</v-col>
              <v-col class="text-uppercase font-weight-bold">Deposit</v-col>
              <v-col>{{ parent_data.deposit_amount }} {{ parent_data.currency }}</v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col>
                <h6 class="text-uppercase font-weight-bold">After rental</h6>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-uppercase font-weight-bold">Qty Extra KM</v-col>
              <v-col>{{ parent_data.after_extra_km }} km</v-col>
              <v-col class="text-uppercase font-weight-bold">Cost Extra gas</v-col>
              <v-col>{{ Math.max((100 - parent_data.after_gas_percent) * 1.5, 0) }} {{ parent_data.currency }}</v-col>

            </v-row>
            <v-row>
              <v-col class="text-uppercase font-weight-bold">Cost Damage</v-col>
              <v-col>{{ parent_data.after_damage }} {{ parent_data.currency }}</v-col>
              <v-col class="text-uppercase font-weight-bold">Rounding</v-col>
              <v-col>{{ parent_data.after_rebate }} {{ parent_data.currency }}</v-col>
            </v-row>
            <v-row>
              <v-col class="text-uppercase font-weight-bold">Extra to pay</v-col>
              <v-col>{{ parent_data.after_extra_total }} {{ parent_data.currency }}</v-col>
              <v-col></v-col>
              <v-col></v-col>
            </v-row>
            <v-row>
              <v-col class="text-uppercase font-weight-bold">Amount paid</v-col>
              <v-col>{{ parent_data.amount_paid }} {{ parent_data.currency }} {{ parent_data.amount_paid != 0 ?
                '(' + parent_data.payment_mode + ")" : ""
                }}</v-col>
              <v-col class="text-uppercase font-weight-bold">To pay</v-col>
              <v-col>{{ topay(parent_data) }} {{ parent_data.currency }}</v-col>
            </v-row>
          </div>
        </div>
        <div class="card pb" style="margin-top:10px;width:100%;min-height:450px;">
          <div class="card-body">
            <h5 class="text-uppercase font-weight-bold card-title">Terms and conditions</h5>
            <v-row>
              <div class="tc caption text-justify">
                <div class="section">
                  <p>This Rental Agreement (the "Agreement") confirms the binding terms and conditions agreed upon by
                    both parties.</p>
                </div>

                <div class="section">
                  <h2>1. Acknowledgment of Terms and Conditions</h2>
                  <p>The undersigned acknowledges having read, understood, and accepted the terms and conditions of this
                    Agreement, including the applicable rental rates.</p>
                </div>

                <div class="section">
                  <h2>2. Authorization for Payment</h2>
                  <p>The undersigned hereby authorizes Riviera Rentals to charge the full amount of the invoice, as well
                    as any ancillary fees or deductible amounts, to the provided bank account or payment card. This
                    authorization extends for up to three (3) months following the end of the rental period.</p>
                </div>

                <div class="section">
                  <h2>3. Insurance Policy</h2>
                  <p>The undersigned agrees to benefit from Riviera Rentals' insurance policy, subject to a deductible
                    of CHF 20,000.</p>
                </div>

                <div class="section">
                  <h2>4. Deposit Retention</h2>
                  <p>Riviera Rentals reserves the right to retain the security deposit for a minimum of three (3) days
                    and a maximum of thirty (30) days after the conclusion of the rental period.</p>
                </div>

                <div class="section">
                  <h2>5. Obligations Upon Vehicle Return</h2>
                  <p>At the time of returning the vehicle, the undersigned must inform Riviera Rentals of any potential
                    violations of the Federal Law on Road Traffic (LCR) or any other offenses committed during the
                    rental period.</p>
                </div>

                <div class="section">
                  <h2>6. Liability for Penal Offenses and Fines</h2>
                  <p>In the event of a penal offense or a fine, the undersigned agrees to bear the full cost of such
                    penalties, as well as any associated administrative or processing fees.</p>
                </div>

                <div class="section">
                  <h2>7. Liability in Case of Accidents</h2>
                  <p>If an accident occurs during the rental period, the undersigned acknowledges that additional
                    charges may be imposed, including but not limited to, up to twenty (20) days of rental fees to cover
                    vehicle immobilization.</p>
                </div>

                <div class="section">
                  <h2>8. Rental Duration and Cancellation</h2>
                  <p>The undersigned commits to adhering to the agreed rental duration. Rentals are deemed final and
                    non-cancellable unless cancellation is communicated at least forty-eight (48) hours prior to the
                    commencement of the rental period. No refunds shall be issued for cancellations made within or after this
                    48-hour window.</p>
                </div>

                <div class="section">
                  <h2>9. Acknowledgment of Debt</h2>
                  <p>This Agreement and the associated terms and conditions constitute an acknowledgment of debt
                    ("reconnaissance de dette") within the meaning of Article 82 of the Swiss Civil Code.</p>
                </div>
                By signing below, the undersigned confirms acceptance of all terms and conditions set forth in this
                Agreement.
              </div>

              <div class="text-uppercase font-weight-bold">I hereby accept all Riviera Rentals' terms and conditions: 
              </div>
              <div class="text-center mt-3" style="max-height:300px;">
                <img height="250"
                  :src="parent_data.signature_data">
              </div>
              <div class="text-uppercase font-weight-bold">
                <p>{{ parent_data.signature_date }}, {{ parent_data.firstname }}
                {{ parent_data.lastname }}</p>
              </div>
            </v-row>
          </div>
        </div>
      </section>
    </section>
  </div>

</template>

<style>
@import "https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css";

.content {
  margin: 30px;
  font-size: 80%;
}

.pdf-item .col {
  padding: 5px;
}

.pdf-item .card {
  margin-bottom: 15px;
}

.pdf-item .card-body {
  padding-top: 6px;
}

.pdf-item h4 {
  font-size: 180%;
}

.pdf-item h5 {
  padding-bottom: 10px;
}

.tc {
  font-size:10px;
}
.section p{
  font-size: 10px;
  margin-bottom: 0px;
  padding-bottom:5px;
}

.section h2 {
  font-size: 10px;
  font-weight: bold;
  padding-bottom: 0px;
  margin-bottom: 0px;
}
</style>

<style type="text/css">
@media print {
  body {
    transform: scale(1.0);
    width: 850px;
    max-width: 850px !important;
    min-width: 850px !important;
  }

  .disappearmedia {
    display: none;
  }

  .content {
    width: 820px;
  }

  @page {
    margin-top: 1cm;
    margin-left: 1cm;
    margin-right: 1.5cm;

    size: portrait;
  }
}
</style>


<script>
import DataService from "../services/DataService";
import { topay, share } from "../plugins/riviera.js";
import html2pdf from 'html2pdf.js'

function initialState() {
  return {
    initialDataConfiguration: null,
    e1: 1,
    is_new_client: -1,
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    country: 'CH',
    citizenship: 'CH',
    telephone: '00',
    firstname: '',
    lastname: '',
    npa: '',
    city: '',
    address: '',
    id_type: '',
    idnumber: '',
    email: '',
    birthdate: new Date().toISOString().substr(0, 10),
    oldclientname: '',
    id_images: [],
    id_images_indexes: [],
    expiration: '',
    permit_id: '',
    damage_images: [],
    damages: [],
    damage_images_data: [],
    vehicle_list_items: [],
    vehicle_data: [],
    vehicle: null,
    car_id: null,
    car_km: '',
    dates: [],
    price_per_day: '',
    extra_km: 0,
    price_extra_km: 1.0,
    currency_list: ['CHF', 'EUR'],
    currency: 'CHF',
    time_start: null,
    time_stop: null,
    place_start: '',
    place_stop: '',
    vehicle_full_percent: 100,
    modal1: false,
    modal2: false,
    vat: 8.1,
    delivery: 0.0,
    extra_costs: 0.0,
    fees: 5,
    payment_mode: 'Card online',
    payment_modes: ['Card online', 'Card terminal', 'Cash', 'Bank transfer'],
    signature_data: null,
    contract_pdf: null,
    birthdatemenu: false,
    signature_date: new Date().toISOString().substr(0, 10),
    subtotal: 0,
    total: 0,
    payed: false,
    payedlabel: "Mark as payed",
    saved: false,
    searchClient: "",
    clientsnames: [],
    validate_text_form: '',
    date_start: null,
    date_end: null,
    id: -1,
    amount_paid: 0,
    after_total_km: 0,
    after_extra_km: 0,
    after_rebate: 0,
    after_damage: 0,
    after_gas_percent: -1,
    after_remarks: '',
    included_km: 100,
    after_extra_total: 0,
  }
}

export default {
  name: 'ContractPDF',
  components: {

  },
  data: function () {
    return {
      parent_data: initialState(),
      contract_uuid: 0,
    }
  },
  mounted: function () {
    this.contract_uuid = this.$route.params.contract_uuid;
    this.loadFromServer(this.contract_uuid);
  },
  methods: {
    getColor(item) {
      return getColor(item);
    },
    topay(item) {
      return topay(item);
    },
    share() {
      return share(this.parent_data);
    },
    print() {
      window.print();
    },
    pdf() {
      var source = document.getElementById('contratpdf');
      source.classList.add('print');
      var opt = {
        margin: 1,
        filename: document.title + '.pdf',
        image: { type: 'jpeg', quality: 0.93 },
        html2canvas: { scale: 1.5 },
        pagebreak: { before: [".pb"] },
        jsPDF: { unit: 'cm', format: 'A4', orientation: 'portrait' }
      };

      // New Promise-based usage:
      html2pdf(this.$refs.contratpdf, opt).then(function () {
        source.classList.remove('print');
      });
    },
    numberFormat(number, width) {
      return new Array(+width + 1 - (number + '').length).join('0') + number;
    },

    async loadFromServer(contract_uuid) {

      let returned = await DataService.getcontract(contract_uuid);
      console.log(returned);
      let client = returned.data.clients;
      let contract = returned.data.contracts;
      let car = returned.data.cars;
      let parent_data = this.parent_data;
      let final_data = { ...parent_data, ...car, ...contract, ...client };
      this.parent_data = final_data;
      console.log("Final data replaced");
      this.parent_data.id_images = JSON.parse(this.parent_data.id_pictures);
      this.parent_data.damage_images = JSON.parse(this.parent_data.damage_pictures);
      this.parent_data.damages = JSON.parse(this.parent_data.damages);
      try {
        this.parent_data.signature_data = JSON.parse(contract.signature_picture)['signature_data'];

      } catch (error) {
        console.log("PDF not signed")
      }
      console.log("images placed");
      document.title = "Riviera Rentals - " + this.parent_data.lastname + " " + this.parent_data.firstname + " " + this.parent_data.signature_date;
      if (this.parent_data.after_extra_total > 0) {
        this.parent_data.after_gas_percent = 100;
      }
    },
  },
}
</script>
