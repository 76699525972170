<template>
  <div>
    <div v-if="this.$route.params.contract_uuid !== undefined && contract !== null && !modalSign">
      <div class="text-center mb-6 mt-6">
        <h1>Contract #{{ contract.contract_number }}</h1>
        <p>Total: {{ contract.total }} {{ contract.currency }}</p>
        <p>Deposit: {{ contract.deposit_amount }} {{ contract.currency }}</p>
        <p>To pay: {{ topay(contract) }} {{ contract.currency }}</p>
        <div class="text-center mb-6">
          <v-btn class = "button-fix" :to="{ name: 'contractpdf', params: { contract_uuid: contract_uuid } }" primary>
            View contract
          </v-btn>
        </div>
        <div class="text-center mb-6">
          <v-btn
            v-if="contract !== null && !this.alreadysigned && (contract.signature_picture == null || contract.signature_picture.length < 4)"
            primary class = "button-fix" @click="modalSign = true; resizeCanvas();">
            Sign contract
          </v-btn>
          <v-btn v-else primary disabled class = "button-fix" >
            Contract already signed
          </v-btn>
        </div>
        <div class="text-center mb-6" v-if="Number(contract.deposit_amount) > 0">
          <v-btn color="primary" class = "button-fix" 
            :to="{ name: 'payments', params: { deposit:Number(contract.deposit_amount), guest:1, contractnumber: contract.contract_number, sa: is_sa(contract), name: contract.firstname + ' ' + contract.lastname, currency: contract.currency, value: 0 } }">
            Pay only deposit
          </v-btn>
        </div>
        <div class="text-center mb-6">
          <v-btn color="primary" class = "button-fix" 
            :to="{ name: 'payments', params: { deposit:Number(contract.deposit_amount), guest:1, contractnumber: contract.contract_number, sa: is_sa(contract), name: contract.firstname + ' ' + contract.lastname, currency: contract.currency, value: (Number(topay(contract))) } }">
            Pay total invoice
          </v-btn>
        </div>

      </div>
    </div>
    <div v-else>
      <div class="text-center mb-6 mt-6">Loading...</div>
    </div>

    <v-dialog v-model="modalSign" width="500" fullscreen>

      <v-card>
        <v-card-title class="headline grey lighten-2">
          Sign contract
        </v-card-title>

        <div class="mb-6 text-center">
          By signing below, the undersigned confirms acceptance of all terms and conditions set forth in the Agreement.
          <terms-and-conditions class="mt-6"></terms-and-conditions>
          <div v-if="signature_data == null" style="margin:auto;">
            <VueSignaturePad width="380px" height="380px" style="margin:auto;" ref="signaturePad" />
          </div>
          <div v-else><img height="300" :src="signature_data"></div>
          <v-btn @click="undoSign">Reset signature</v-btn>
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="saveSignature()"  :loading="buttonLoading">
            Save
          </v-btn>
          <v-btn color="primary" @click="modalSign = false;">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import DataService from "../services/DataService";
import VueSignaturePad from './SignaturePad.vue';
import TermsAndConditions from '../components/TermsAndConditions.vue'
import {is_finished, is_paid, topay, share, is_sa} from "../plugins/riviera.js";

export default {

  name: 'ClientPortal',
  components: { VueSignaturePad, TermsAndConditions },
  data() {
    return {
      contract_uuid: '',
      contract: null,
      modalSign: false,
      signature_data: null,
      alreadysigned: false,
      buttonLoading: false,
    }
  },
  computed: {
  },
  methods: {
      topay(item)
      {
        return topay(item);
      },
      share(item)
      {
        return share(item);
      },
      is_sa(item)
      {
        return is_sa(item);
      },
    async saveSignature() {
      this.buttonLoading = true;
      let signature_picture = await this.getSignaturePicture();
      let post_input = { signature_date: new Date().toISOString().substr(0, 10), signature_picture: signature_picture };
      await DataService.sign(this.contract.uuid, post_input)
        .then(response => {
          console.log(response.data);
          if (response.data.error === true) {
            console.log("error " + response.data.message).
            this.buttonLoading = false;
          }
          else {
            console.log("Saved!");
            this.buttonLoading = false;
            this.modalSign = false;
          }
        })
        .catch(e => {
          console.log(e);
          this.buttonLoading = false;
        });

    },
    getSignatureData() {
      if (this.signature_data == null) {
        console.log("Signature pad " + this.$refs.signaturePad.signaturePad._isEmpty)
        if (!this.$refs.signaturePad.signaturePad._isEmpty)
          this.$localforage.setItem("signature_data", this.$refs.signaturePad.signaturePad.canvas.toDataURL());
        else
          console.log("Signature not done.")
      }
      else
        console.log("Signature not null")
    },

    
    async getSignaturePicture() {
      this.getSignatureData();
      if (this.signature_data != null) {
        return JSON.stringify({ signature_data: this.signature_data });
      }

      let data = await this.$localforage.keys();
      let to_send = {};
      console.log("length of data pictures" + data.length)
      if (data.length > 0) {
        for (const image_name of data) {
          if (image_name.indexOf("signature") > -1) {
            to_send[image_name] = await this.$localforage.getItem(image_name);
          }
        }
      }

      return JSON.stringify(to_send);

    },

    resizeCanvas() {
      if (this.signature_data == null) {
        this.$nextTick(() => {
          this.$nextTick(() => {
            this.$nextTick(() => {
              this.$refs.signaturePad.resizeCanvas();
            })
          })
        });
      }
    },
    undoSign() {
      this.signature_data = null;
      if (this.$refs.signaturePad) {
        this.$refs.signaturePad.undoSignature();
      }
      this.resizeCanvas();
    },
  },

  async mounted() {
    this.contract_uuid = this.$route.params.contract_uuid;
    let returned = await DataService.getcontract(this.contract_uuid);
    console.log(returned);
    let client = returned.data.clients;
    let contract = returned.data.contracts;
    let car = returned.data.cars;
    this.contract = { ...car, ...contract, ...client };
    try {
      this.signature_data = JSON.parse(contract.signature_picture)['signature_data'];
      if (this.signature_data.length > 3)
        this.alreadysigned = true;
    } catch (error) {
      console.log("no signature found " + error)
      this.signature_data = null;
    }

  },
}
</script>
