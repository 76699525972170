<template>
  <v-stepper v-model="e1" style="height:100%">
    <v-stepper-header>
      <v-stepper-step :complete="e1 > 1" step="1" editable>
        Customer
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="e1 > 2" step="2" editable>
        Vehicle
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="e1 > 3" step="3" editable>
        Pricing
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="e1 > 4" step="4" @click="resizeCanvas()">
        Signature
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="e1 > 5" step="5" :editable="finish">
        Finish
      </v-stepper-step>
      <v-divider></v-divider>

      <v-stepper-step :complete="e1 > 6" step="6">
        Payment
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <div class="text-center mb-6">
          <img height="90" src="../../public/logogrand.png">
        </div>
        <div class="text-center mb-3">
          <v-btn v-if="is_new_data" @click="startAgain()" class="button-fix">
            New Contract
          </v-btn>
        </div>
        <div class="text-center mb-3">
          <v-btn @click="is_new_client = 1;" class="button-fix">
            New customer
          </v-btn>
        </div>
        <div class="text-center mb-3">
          <v-btn @click="is_new_client = 0;" class="button-fix">
            Search customer
          </v-btn>
        </div>

        <div class="mb-6 pt-3">
          <v-autocomplete v-if="is_new_client == 0" clearable :search-input.sync="searchClient" dense filled
            ref="namesearch" label="Name" :items="clientsnames" :item-text="'fullname'" v-model="oldclientname"
            @input="loadOldClient"></v-autocomplete>

          <v-form ref="clientform" v-if="is_new_client == 1">
            <text-field-outlined v-model="firstname" label="First name" required :rules="requiredRules"></text-field-outlined>

            <text-field-outlined v-model="lastname" label="Last name" required :rules="requiredRules"></text-field-outlined>

            <text-field-outlined v-model="address" label="Address"></text-field-outlined>
            <v-row>
              <v-col cols="5" style="padding-top:0; padding-bottom:0;">
                <text-field-outlined v-model="npa" label="PC"></text-field-outlined>
              </v-col>
              <v-col style="padding-top:0; padding-bottom:0;">
                <text-field-outlined v-model="city" label="City"></text-field-outlined>
              </v-col>
            </v-row>
            <country-select-outlined label="Country" :rules="requiredRules" v-model="country" />

            <text-field-outlined v-model="email" label="E-mail" :rules="emailRules"></text-field-outlined>

            <text-field-outlined v-model="telephone" label="Telephone" type="number"></text-field-outlined>

            <country-select-outlined label="Citizenship" required v-model="citizenship" />

            <text-field-outlined v-model="permit_id" label="Driving licence Nr."></text-field-outlined>

            <v-dialog ref="dialog" v-model="birthdatemenu" :return-value.sync="birthdate" persistent width="290px">
              <template v-slot:activator="{ on, attrs }">
                <text-field-outlined v-model="birthdate" label="Birth date" readonly v-bind="attrs" v-on="on"></text-field-outlined>
              </template>
              <v-date-picker v-model="birthdate" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="birthdatemenu = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.dialog.save(birthdate)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>


            <v-row>
              <v-col cols="4" style="padding-top:0; padding-bottom:0;">
                <select-outlined :items="id_types" label="ID Type" v-model="id_type"></select-outlined>
              </v-col>
              <v-col style="padding-top:0; padding-bottom:0;">
                <text-field-outlined v-model="idnumber" label="ID Number"></text-field-outlined>
              </v-col>
              <v-col style="padding-top:0; padding-bottom:0;">
                <text-field-outlined v-model="expiration" label="Exp"></text-field-outlined>
              </v-col>
            </v-row>

            <v-row style="margin-bottom:10px;">
              <v-col class="text-uppercase font-weight-bold">ID pictures</v-col>
            </v-row>
            <div v-for="(image, index) in id_images" v-bind:key="index" class="mb-6">
              <img style="max-width: 100%;" :src='id_images[index]'> <v-btn class="mt-3" @click="delete_id_image(index)">Delete</v-btn>
            </div>

            <vue-upload-multiple-image class="pt-12" style="min-width:100%" @upload-success="upload_id_image_sucess"
              :data-id_images="id_images" primaryText="Default image" dragText="Take or drag ID picture"
              markIsPrimaryText="Set as default" :maxImage=4 browseText="" popupText="" :showDelete="false"
              :showEdit="false" :showPrimary="false"></vue-upload-multiple-image>

            <div class="text-center mt-6">
              {{ validate_text_form }}
              <v-btn color="primary" @click="validateClientForm" class="button-fix">
                Continue
              </v-btn>
            </div>

          </v-form>
        </div>
      </v-stepper-content>

      <v-stepper-content step="2">
        <div class="mb-6">
          <v-form ref="carform">
            <select-outlined :items="vehicle_list_items" label="Vehicle" v-model="vehicle" :rules="vehiculeRequiredRules"
              @change="changeVehicle"></select-outlined>
            <v-row>
              <v-col cols=6>
                <text-field-outlined label="Mileage" v-model="car_km" value=0 suffix="km" type="number" :rules="vehiculeRequiredRules">
                </text-field-outlined>
              </v-col>
              <v-col cols=6>
                <text-field-outlined label="Gas" v-model="vehicle_full_percent" :rules="vehiculeRequiredRules" value=100 type="number"
                  suffix="%"></text-field-outlined>
              </v-col>
            </v-row>


            <vue-upload-multiple-image v-if="damage_pictures.length < 1" class="pt-2" idEdit="damage-edit"
              idUpload="damage-upload" style="min-width:100%" @upload-success="upload_damage_image_sucess"
              :dataImages="damage_images_data" primaryText="Default image" dragText="Take damage picture"
              markIsPrimaryText="Set as default" :maxImage=8 browseText="" popupText="" :showDelete="false"
              :showEdit="false" :showPrimary="false"></vue-upload-multiple-image>

            <div v-for="(image, index) in damage_pictures" v-bind:key="index">
              <img style="max-width: 100%;" :src='damage_pictures[index]'>
            </div>

            <div v-for="(damage, index) in damages" v-bind:key="index">
              <text-field-outlined v-model="damage.text" :key="index" label="Description of damage"></text-field-outlined>
            </div>
          </v-form>
        </div>

        <div class="text-center">
          {{ validate_text_form }}
          <v-btn v-if="is_new_client >= 0" color="primary" @click="validateCarForm" class="button-fix">
            Continue
          </v-btn>
        </div>

      </v-stepper-content>

      <v-stepper-content step="3">
        <div class="mb-6 text-center">
          <v-form ref="contractform">
            <text-field-outlined v-model="dateRangeText" label="Date range" prepend-icon="mdi-calendar" readonly
              :rules="requiredRules"></text-field-outlined>

            <v-date-picker v-model="dates" range></v-date-picker>
            <v-row>
              <v-col>
                <v-dialog ref="dialog1" v-model="modal1" persistent width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <text-field-outlined v-model="time_start" label="Start" prepend-icon="mdi-clock-time-four-outline" readonly
                      :rules="requiredRules" v-bind="attrs" v-on="on"></text-field-outlined>
                  </template>
                  <v-time-picker v-if="modal1" v-model="time_start" format="24hr" :allowed-minutes="allowedStep"
                    full-width @click:minute="$refs.dialog1.save(time_start)">
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal1 = false;">
                      OK
                    </v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-col>
              <v-col>
                <v-dialog ref="dialog2" v-model="modal2" persistent width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <text-field-outlined v-model="time_stop" label="End" :rules="requiredRules"
                      prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on"></text-field-outlined>
                  </template>
                  <v-time-picker v-if="modal2" v-model="time_stop" format="24hr" :allowed-minutes="allowedStep"
                    @click:minute="$refs.dialog2.save(time_stop)" full-width>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal2 = false;">
                      OK
                    </v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-col>
            </v-row>


            <text-field-outlined v-model="dateRangeNumber" label="Rental duration" prepend-icon="mdi-calendar" readonly
              suffix="days"></text-field-outlined>

            <!--<v-row>
              <v-col cols=6>
                <v-combobox v-model="place_start" :items="locations_propositions" outlined label="Delivery location" clearable>
                </v-combobox>
              </v-col>
              <v-col cols=6>
                <v-combobox v-model="place_stop" :items="locations_propositions" outlined label="Return location" clearable>
                </v-combobox>
              </v-col>

            </v-row> -->

            <v-row>
              <v-col cols=6>
                <select-outlined :items="currency_list" v-model="currency" label="Currency"></select-outlined>
              </v-col>
              <v-col cols=6>
                <text-field-outlined @input="updateExtraKM" v-model="price_per_day" :label="'Price per day'"
                  :rules="requiredRules" :suffix="'' + currency + ''" type="number"></text-field-outlined>
              </v-col>

            </v-row>


            <text-field-outlined :value="price_per_day * dateRangeNumber" readonly :label="'Sub-total'"
              :suffix="'' + currency + ''">
            </text-field-outlined>


            <v-row>
              <v-col cols=4>
                <text-field-outlined @input="updateExtraKM" v-model="included_km" label="Qty included KM / day" :rules="requiredRules"
                  suffix="km" type="number"></text-field-outlined>
              </v-col>
              <v-col cols=4>
                <text-field-outlined v-model="extra_km" label="Qty extra KM" value=0 suffix="km" type="number"></text-field-outlined>
              </v-col>
              <v-col cols=4>
                <text-field-outlined v-model="price_extra_km" :label="'Price extra KM'" :rules="requiredRulesOrZero" :suffix="'' + currency + ''"
                   type="number"></text-field-outlined>
              </v-col>

            </v-row>

            <v-row>
              <v-col cols=6>
                <text-field-outlined v-model="delivery" :label="'Delivery fees'" :rules="requiredRulesOrZero" :suffix="'' + currency + ''" type="number"
                  value=0></text-field-outlined>
              </v-col>
              <v-col cols=6>
                <text-field-outlined v-model="deposit_amount" :label="'Deposit'" :rules="requiredRulesOrZero" type="number" value=0
                  :suffix="'' + currency + ''"></text-field-outlined>
              </v-col>
              </v-row>
            <v-row>
              <v-col cols=4>
                <text-field-outlined v-model="extra_costs"  :rules="requiredRulesOrZero" :label="'Extra costs'" type="number" value=0
                  ></text-field-outlined>
              </v-col>
              <v-col cols=4>
                <text-field-outlined v-model="before_discount" :label="'Discount '+ currency" type="number" :rules="requiredRulesOrZero"
                 ></text-field-outlined>
              </v-col>
              <v-col cols=4>
                <select-outlined :items="vats" v-model="vat" :rules="requiredRulesOrZero" value="0" label="VAT" suffix="%"></select-outlined>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols=6>
                <select-outlined :items="payment_modes" v-model="payment_mode" label="Payment mode" :rules="requiredRules"
                  @change="onChangePaymentMode()"></select-outlined>
              </v-col>
              <v-col cols=6>
                <text-field-outlined v-model="fees" :rules="requiredRulesOrZero" label="Payment fees" suffix="%" type="number"></text-field-outlined>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols=3>
                <select-outlined :items="is_sa_choices" v-model="is_sa" label="Account"></select-outlined>
              </v-col>
              <v-col cols=3>
                <v-switch v-model="public" label="Public"></v-switch>
              </v-col>
              <v-col cols=6>
              <text-field-outlined v-model="countTotal" readonly prepend-icon="mdi-cash" :label="'Total VAT included'"
                :suffix="'' + currency + ''" :rules="requiredRules"></text-field-outlined>
              </v-col>
            </v-row>

          </v-form>
        </div>

        <div class="text-center">
          {{ validate_text_form }}
          <v-btn color="primary" @click="validateContractForm(); resizeCanvas();" class="button-fix">
            Continue
          </v-btn>
        </div>
      </v-stepper-content>

      <v-stepper-content step="4">
        <div class="mb-6 text-center">
          By signing below, the undersigned confirms acceptance of all terms and conditions set forth in the Rental Agreement. 
          <terms-and-conditions class="mt-6"></terms-and-conditions>
          <div v-if="signature_data == null" style="margin:auto;">
            <VueSignaturePad width="380px" style="margin:auto;" height="380px" ref="signaturePad" />
          </div>
          <div v-else><img height="300" :src="signature_data"></div>
          <v-btn class="button-fix" @click="undoSign">Reset signature</v-btn>
        </div>

        <div class="text-center">
          {{ validate_text_form }}
          <br />
          <v-btn class="button-fix" color="primary" ref="saveformbutton" :loading="buttonLoading"
            @click="getSignatureData(); saveForm();">
            Save
          </v-btn>
        </div>
        <div class="text-center mt-3">
          <v-btn class="button-fix" color="primary" ref="saveformbutton" :loading="buttonLoading"
            @click="signature_data = null; saveForm();">
            Skip and share link
          </v-btn>
        </div>


      </v-stepper-content>

      <v-stepper-content step="6">
        <div v-if="saved == true" class="text-center mb-6">
          <h2>Contract saved</h2>
          <div class="text-center mb-3">
            <p>Total: {{ countAfterToPay }} {{ currency }}</p>
            <p>Deposit: {{ deposit_amount }} {{ currency }}</p>
            <v-btn class="button-fix" color="primary"
              :to="{ name: 'payments', params: { name: firstname + ' ' + lastname, currency: currency, value: (Number(countAfterToPay) + Number(deposit_amount)) } }">
              Continue to payment
            </v-btn>
          </div>
          <div class="text-center mb-3">
            <v-btn class="button-fix" :to="{ name: 'contractpdf', params: { contract_uuid: uuid } }" primary>
              PDF
            </v-btn>
          </div>
          <div class="text-center mb-3">
            <v-btn @click="share($data)" primary class="button-fix">
              Share link
            </v-btn>
          </div>
          <div class="text-center mb-3">
            <v-btn @click="startAgain()" primary class="button-fix">
              Start again
            </v-btn>
          </div>
        </div>
        <div v-else class="text-center mb-6">
          <h2>Please save contract first.</h2>
        </div>

      </v-stepper-content>

      <v-stepper-content step="5">
        <div class="mb-6">
          <v-form ref="finishform">
            <v-row>
              <v-col cols=6>
                <text-field-outlined label="Mileage" v-model="after_total_km" :rules="requiredRules" suffix="km" type="number">
                </text-field-outlined>
              </v-col>
              <v-col cols=6>
                <text-field-outlined label="Gas" v-model="after_gas_percent" :rules="requiredRules" type="number" suffix="%">
                </text-field-outlined>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <text-field-outlined v-model="after_remarks" :label="'Remarks'" clearable></text-field-outlined>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols=4>
                <text-field-outlined v-model="price_extra_km" :label="'Price extra KM'" :rules="requiredRules"
                  :suffix="'' + currency + ''" type="number"></text-field-outlined>
              </v-col>
              <v-col cols=4>
                <text-field-outlined label="Total extra KM" v-model="countAfterExtraKM" suffix="km" readonly></text-field-outlined>
              </v-col>
              <v-col cols=4>
                <text-field-outlined :label="'Total price extra KM'" v-model="countAfterExtraKMPrice"
                  :suffix="'' + currency + ''" readonly></text-field-outlined>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols=6>
                <text-field-outlined v-model="after_damage" :label="'Damage fees'" type="number" :rules="requiredRules"
                  :suffix="'' + currency + ''"></text-field-outlined>
              </v-col>
              <v-col cols=6>
                <text-field-outlined v-model="after_rebate" label="Rebate" type="number" :rules="requiredRules"
                  :suffix="'' + currency + ''"></text-field-outlined>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols=6>
                <text-field-outlined v-model="delivery" :label="'Delivery fees'" :suffix="'' + currency + ''" type="number">
                </text-field-outlined>
              </v-col>
              <v-col cols=6>
                <text-field-outlined :label="'Total extra'" v-model="countAfterTotal" readonly :suffix="'' + currency + ''">
                </text-field-outlined>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col cols=4>
                <text-field-outlined v-model="countTotal" label="Rental cost" readonly
                  :suffix="'' + currency + ''"></text-field-outlined>
              </v-col>
              <v-col cols=4>
                <text-field-outlined v-model="amount_paid" :label="'Paid'" :suffix="'' + currency + ''" type="number">
                </text-field-outlined>
              </v-col>
              <v-col cols=4>
                <text-field-outlined :label="'Total to pay'" v-model="countAfterToPay" readonly :suffix="'' + currency + ''">
                </text-field-outlined>
              </v-col>
            </v-row>

          </v-form>
        </div>

        <div class="text-center">
          {{ validate_text_form }}
          <br />
          <v-btn color="primary" :loading="buttonLoading" @click="saveFormFinish()">
            Save and pay
          </v-btn>
        </div>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>


<style>
.image-container {
  width: 100% !important;
}

a:hover {
  text-decoration: none;
}

.row + .row
{
  margin-top: 0px;
}
</style>


<script>
import Vue from 'vue'

import { VCountrySelect } from '@timbouc/vuetify-country-region-input'
import VueUploadMultipleImage from 'vue-upload-multiple-image'
import VueSignaturePad from './SignaturePad.vue';
import TermsAndConditions from '../components/TermsAndConditions.vue'
import ContractPDF from './ContractPDF.vue'
import VueLocalforage from 'v-localforage'
import DataService from "../services/DataService";
import { topay, share } from "../plugins/riviera.js";
import _ from 'lodash';

Vue.use(VueLocalforage)
Vue.use(VueSignaturePad)

function resizebase64(base64, maxWidth, maxHeight) {
  // Max size for thumbnail
  if (typeof (maxWidth) === 'undefined') maxWidth = 500;
  if (typeof (maxHeight) === 'undefined') maxHeight = 500;

  // Create and initialize two canvas
  var canvas = document.createElement("canvas");
  var ctx = canvas.getContext("2d");
  var canvasCopy = document.createElement("canvas");
  var copyContext = canvasCopy.getContext("2d");

  // Create original image
  var img = new Image();
  img.src = base64;

  // Determine new ratio based on max size
  var ratio = 1;
  if (img.width > maxWidth)
    ratio = maxWidth / img.width;
  else if (img.height > maxHeight)
    ratio = maxHeight / img.height;

  // Draw original image in second canvas
  canvasCopy.width = img.width;
  canvasCopy.height = img.height;

  copyContext.drawImage(img, 0, 0);
  // Copy and resize second canvas to first canvas
  canvas.width = canvasCopy.width * ratio;
  canvas.height = canvasCopy.height * ratio;
  ctx.drawImage(canvasCopy, 0, 0, canvasCopy.width, canvasCopy.height, 0, 0, canvas.width, canvas.height);


  return canvas.toDataURL('image/jpeg', 0.80);
}

function initialState() {
  return {
    initialDataConfiguration: null,
    e1: 1,
    is_new_client: -1,
    id_types: ['Passport', 'ID card', 'Driving licence'],
    country: 'CH',
    citizenship: 'CH',
    telephone: '00',
    firstname: '',
    lastname: '',
    npa: '',
    city: '',
    address: '',
    id_type: '',
    idnumber: '',
    email: '',
    birthdate: new Date().toISOString().substr(0, 10),
    oldclientname: '',
    id_images: {},
    expiration: '',
    permit_id: '',
    damage_images: [],
    damages: [],
    damage_images_data: [],
    vehicle_list_items: [],
    emailRules: [],
    requiredRules: [],
    timeRules: [],   // custom time validation rules
    vehicle_data: [],
    vehicle: null,
    car_id: null,
    car_km: '',
    dates: [],
    price_per_day: '',
    extra_km: 0,
    price_extra_km: 1.0,
    currency_list: ['CHF', 'EUR'],
    currency: 'CHF',
    time_start: null,
    time_stop: null,
    place_start: '',
    place_stop: '',
    vehicle_full_percent: 100,
    modal1: false,
    modal2: false,
    vat: 0,
    delivery: 0.0,
    extra_costs: 0.0,
    fees: 5,
    payment_mode: 'Card online',
    payment_modes: ['Card online', 'Card terminal', 'Cash', 'Bank transfer'],
    vats: [0.0, 8.1],
    signature_data: null,
    contract_pdf: null,
    birthdatemenu: false,
    signature_date: new Date().toISOString().substr(0, 10),
    subtotal: 0,
    total: 0,
    payed: false,
    payedlabel: "Mark as payed",
    saved: false,
    searchClient: "",
    clientsnames: [],
    validate_text_form: '',
    date_start: null,
    date_end: null,
    id: -1,
    amount_paid: 0,
    after_total_km: '',
    after_extra_km: 0,
    after_rebate: 0,
    before_discount: 0,
    after_damage: 0,
    after_gas_percent: 100,
    after_remarks: '',
    included_km: 100,
    after_extra_total: 0,
    deposit_amount: 0,
    damage_pictures: [],
    finish: false,
    buttonLoading: false,
    locations_propositions: [
      'Geneva',
      'Geneva Airport',
      'Verbier',
      'Leysin',
      'Milan',
      'Montreux',
      'Nyon',
      'Zurich',
      'Zurich Airport',
      'Cannes'
    ],
    uuid: '',
    is_new_data: false,
    is_sa: "IN",
    is_sa_choices: ["IN", "SA"],
    sa: 0,
    public: 0,
  }
}


export default {
  name: 'NewContract',
  components: { VueUploadMultipleImage, VueSignaturePad, TermsAndConditions },
  data: function () {
    return initialState();
  },

  computed: {
    countAfterExtraKM() {
      return Math.max(0, Math.max(- Number(this.car_km) + Number(this.after_total_km, 0)) - Number(this.included_km) * Number(this.dateRangeNumber) - Number(this.extra_km));
    },
    countAfterExtraKMPrice() {
      return Number(this.countAfterExtraKM) * Number(this.price_extra_km);
    },
    countFuelPrice() {
      return Number(Math.max(0, (this.vehicle_full_percent - this.after_gas_percent) * 2.0)).toFixed(2);
    },
    countAfterTotal() {
      return Number(Number(this.countAfterExtraKMPrice) + Number(this.after_damage) - Number(this.after_rebate) + Number(this.countFuelPrice)).toFixed(2);
    },
    countAfterToPay() {
      return Number(Number(this.countTotal) + Number(this.countAfterTotal) - Number(this.amount_paid)).toFixed(2);
    },
    countTotal() {
      return Number(0.01 * Math.floor(100 * (((Number(this.price_per_day) * this.dateRangeNumber) - Number(this.before_discount) + (Number(this.extra_km) * Number(this.price_extra_km)) + Number(this.delivery) + Number(this.extra_costs)) * (1 + 0.01 * Number(this.fees)) * (1 + 0.01 * this.vat)))).toFixed(2);
    },
    dateRangeText() {

      return this.dates.join(' ~ ');
    },
    share(item) {
      return share(item);
    },
    dateRangeNumber() {
      if (this.dates.length < 2) {
        return "";
      }
      if (this.time_start === null || this.time_stop === null) {
        return "";
      }
      var date_stop = this.dates[1];
      var date_start = this.dates[0];

      if (date_start > date_stop) {
        date_stop = date_start;
        date_start = this.dates[1];
      }

      var real_date_start = new Date(Date.parse(date_start));
      real_date_start.setHours(this.time_start.split(':')[0])
      real_date_start.setMinutes(this.time_start.split(':')[1])
      var real_date_stop = new Date(Date.parse(date_stop));
      real_date_stop.setHours(this.time_stop.split(':')[0])
      real_date_stop.setMinutes(this.time_stop.split(':')[1])

      //console.log(real_date_start);
      return Number(Math.abs(Math.ceil((real_date_stop - real_date_start) / 86400000.0)));
    },
  },
  methods: {
    updateExtraKM() {
      this.price_extra_km = Math.round(10.0 * this.price_per_day / this.included_km) / 10;
    },
    async loadOldClient(event) {
      console.log("Loaded");
      console.log(event);
      let client_id = (event.substring(event.indexOf("(ID ") + 4)).slice(0, -1);
      await this.loadClientFromId(client_id);
    },
    async loadContractFromuuid(contract_uuid) {
      this.startAgain();
      let returned = await DataService.getcontract(contract_uuid);
      console.log(returned);
      let data = returned.data.contracts;
      for (const data_key of Object.keys(data)) {
        console.log(data_key);
        this[data_key] = data[data_key];
      }
      this.dates = [];
      this.dates.push(this.date_start);
      this.dates.push(this.date_end);
      this.signature_data = JSON.parse(this.signature_picture)['signature_data'];
      const vehicleIndex = this.vehicle_data.findIndex(vehicle => vehicle.id === this.car_id);
      this.vehicle = this.vehicle_list_items[vehicleIndex];
      this.damages = JSON.parse(this.damages);
      this.damage_pictures = JSON.parse(data['damage_pictures']);
      if (this.sa < 0)
      {
        this.sa = this.vat === 8.1 | 0
        console.log("Loaded SA from TVA " + this.sa)
      }
      this.is_sa = this.is_sa_choices[this.sa];

      for (const data_key of Object.keys(this.damage_pictures)) {
        this.$localforage.setItem(data_key, this.damage_pictures[data_key]);
      }
    },
    async loadClientFromId(client_id) {
      let returned = await DataService.getclient(client_id);
      console.log(returned);
      let data = returned.data.clients;
      for (const data_key of Object.keys(data)) {
        console.log(data_key);
        this[data_key] = data[data_key];
      }

      this.id_images = JSON.parse(data['id_pictures']);
      for (const data_key of Object.keys(this.id_images)) {
        this.$localforage.setItem(data_key, this.id_images[data_key]);
      }

      this.is_new_client = 1;
    },
    validateCarForm() {
      if (this.$refs.carform.validate()) {
        this.e1 = 3;
      }
    },

    validateContractForm() {
      if (this.$refs.contractform.validate() && this.dateRangeNumber > 0 && this.validateTimeRange()) {
        console.log("Contract form passed validation.")
        this.e1 = 4;
        this.validate_text_form = '';
      }
      else {
        // popup
        this.validate_text_form = ''
        if (!this.validateTimeRange())
          this.validate_text_form += 'Time not validated'
        if (this.dateRangeNumber < 0)
          this.validate_text_form += 'Date range not validated'
        if (!this.$refs.contractform.validate())
          this.validate_text_form += 'Some fields are missing !';
        this.buttonLoading = false;
      }
    },

    validateTimeRange() {
      if (this.time_start === null || this.time_stop === null) {
        return false;
      }
      return true;
    },

    validateClientForm() {
      if (this.$refs.clientform.validate()) {
        this.e1 = 2;
      }
    },

    changeVehicle(value) {
      this.car_id = this.vehicle_data[this.vehicle_list_items.indexOf(value)].id;
    },

    getData() {
      this.subtotal = this.countTotal;
      this.total = this.countTotal;
      this.after_extra_km = this.countAfterExtraKM;
      this.after_extra_total = this.countAfterTotal;
      this.sa = this.is_sa === "SA" | 0;
      return this.$data;
    },

    async getClientPictures() {
      let data = await this.$localforage.keys();
      let to_send = {};
      console.log("length of data pictures" + data.length)
      if (data.length > 0) {
        for (const image_name of data) {
          if (image_name.indexOf("id_images") > -1) {
            to_send[image_name] = await this.$localforage.getItem(image_name);
          }
        }
      }
      return JSON.stringify(to_send);
    },

    async getContractPictures() {
      let data = await this.$localforage.keys();
      let to_send = {};
      console.log("length of data pictures" + data.length)
      if (data.length > 0) {
        for (const image_name of data) {
          if (image_name.indexOf("damage") > -1) {
            to_send[image_name] = await this.$localforage.getItem(image_name);
          }
        }
      }
      return JSON.stringify(to_send);
    },

    async getSignaturePicture() {
      if (this.signature_data != null) {
        return JSON.stringify({ signature_data: this.signature_data });
      }

      let data = await this.$localforage.keys();
      let to_send = {};
      console.log("length of data pictures" + data.length)
      if (data.length > 0) {
        for (const image_name of data) {
          if (image_name.indexOf("signature") > -1) {
            to_send[image_name] = await this.$localforage.getItem(image_name);
          }
        }
      }

      return JSON.stringify(to_send);

    },

    async getJSONLocalstorage() {
      let data = await this.$localforage.keys();
      let to_send = {};
      for (const image_name of data) {
        to_send[image_name] = await this.$localforage.getItem(image_name);
      }
      return JSON.stringify(to_send);
    },


    logData() {
      console.log(this);
    },

    markAsPayed() {
      console.log("Marked as payed!");
      this.payed = true;
      this.payedlabel = 'Marked as payed!'
    },

    startAgain() {
      console.log("start Again");
      Object.assign(this.$data, initialState());
      this.$localforage.clear();
      this.vehicle_list_items = [];
      DataService.getavailablecars()
        .then(response => {
          for (var i in response.data['cars']) {
            this.vehicle_list_items.push(response.data['cars'][i].brand + " " + response.data['cars'][i].model + " " + response.data['cars'][i].plate + " " + response.data['cars'][i].chassis);
            this.vehicle_data.push(response.data['cars'][i])
          }
        });
      this.is_new_data = false
      localStorage.setItem("data", JSON.stringify(this.$data))
    },

    async saveClient() {
      let data = this.getData();
      let id_data = await this.getClientPictures();
      let post_input = Object.assign({}, data, { id_pictures: id_data });
      let response = await DataService.addclient(post_input);
      console.log("Client data :" + response.data.client);
      return response.data.client;
    },
    async saveFormFinish() {
      if (this.$refs.finishform.validate()) {
        this.saveForm();
      }
      else {
        this.validate_text_form = 'Some fields are missing !';
        this.buttonLoading = false;
      }
    },
    async saveForm() {

      try {
        this.buttonLoading = true;
        if (this.$refs.carform.validate() && this.$refs.contractform.validate() && this.$refs.clientform.validate()) {
          this.date_end = this.dates[1];
          this.date_start = this.dates[0];

          if (this.date_start > this.date_end) {
            this.date_end = this.date_start;
            this.date_start = this.dates[1];
          }

          console.log("Saving form...");
          let data = this.getData();
          let client_data = await this.saveClient();
          console.log("client data added : " + client_data);
          let damage_pictures = await this.getContractPictures();
          let signature_picture = await this.getSignaturePicture();

          let post_input = Object.assign({}, data, { total: this.countTotal, client_id: client_data.id, damage_pictures: damage_pictures, signature_picture: signature_picture });
          delete post_input.id_images;
          delete post_input.vehicle_list_items;
          delete post_input.vehicle_data;
          await DataService.addcontract(post_input)
            .then(response => {
              console.log(response.data);
              if (response.data.error === true) {
                console.log("error " + response.data.message)
                window.alert("error " + response.data.message)
                this.buttonLoading = false;
              }
              else {
                this.saved = true;
                this.uuid = response.data.contract.uuid;
                console.log("Saved!");
              }
            })
            .catch(e => {
              console.log(e);
            });
          this.e1 = 6;
          this.buttonLoading = false;
        }
        else {
          this.validate_text_form = 'Some fields are missing !';
          this.buttonLoading = false;
        }

      }
      catch (error) {
        window.alert(error)
        this.buttonLoading = false;
      }
    },
    resizeCanvas() {
      if (this.signature_data == null) {
        this.$nextTick(() => {
          this.$nextTick(() => {
            this.$nextTick(() => {
              this.$refs.signaturePad.resizeCanvas();
            })
          })
        });
      }
    },
    allowedStep: m => m % 15 === 0,
    getSignatureData() {
      if (this.signature_data == null) {
        console.log("Signature pad " + this.$refs.signaturePad.signaturePad._isEmpty)
        if (!this.$refs.signaturePad.signaturePad._isEmpty)
          this.$localforage.setItem("signature_data", this.$refs.signaturePad.signaturePad.canvas.toDataURL());
        else
          console.log("Signature not done.")
      }
      else
        console.log("Signature not null")
    },
    undoSign() {
      this.signature_data = null;
      if (this.$refs.signaturePad) {
        this.$refs.signaturePad.undoSignature();
      }
      this.resizeCanvas();
    },

    onChangePaymentMode() {
      var data = this.payment_mode;
      if (data == 'Card online' || data == 'Card terminal') {
        this.fees = 5;
      }
      else {
        this.fees = Number(0.0);
      }
    },

    upload_id_image_sucess(formData, index, fileList) {
      var reader = new FileReader();
      reader.readAsDataURL(formData.getAll("file")[0]);
      var len = Object.keys(this.id_images).length
      reader.onload = function () {
        var base64String = reader.result;
        var img = resizebase64(base64String, 900, 900);
        this.$localforage.setItem("id_images_" + (index + len), img);
      }.bind(this);
    },

    delete_id_image(index) {
      console.log(index);
      this.$localforage.removeItem(index);
      var new_image_array = this.id_images;
      delete new_image_array[index];
      this.id_images = new_image_array;
      this.id_images = {
        ...new_image_array,
      }
    },

    upload_damage_image_sucess(formData, index, fileList) {
      var reader = new FileReader();
      reader.readAsDataURL(formData.getAll("file")[0]);
      index = Object.keys(this.damage_pictures).length + index
      this.damage_images.push(index);
      this.damages.push({ text: '' });
      reader.onload = function () {
        var base64String = reader.result;
        var img = resizebase64(base64String, 900, 900);
        this.$localforage.setItem("id_damage_images_" + index, img);
      }.bind(this);
    },
  },
  mounted() {
    this.buttonLoading = false
    if (localStorage.getItem("password") === null) {
      this.$router.push({ name: 'login' })
    }

    if (localStorage.getItem("data") != null) {
      let loadedData = JSON.parse(localStorage.getItem("data"))
      for (const data_key of Object.keys(loadedData)) {
        console.log(data_key, loadedData[data_key]);
        this[data_key] = loadedData[data_key];
      }
    }

    if (!this.is_new_data) {
      this.startAgain()
    }

    DataService.getavailablecars()
      .then(response => {
        for (var i in response.data['cars']) {
          this.vehicle_list_items.push(response.data['cars'][i].brand + " " + response.data['cars'][i].model + " " + response.data['cars'][i].plate + " " + response.data['cars'][i].chassis);
          this.vehicle_data.push(response.data['cars'][i])
        }
      });

    this.requiredRules = [
      v => (!!v) || 'This field is required',
    ]
    this.emailRules = [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be a valid address (e.g., user@example.com)',
    ]

    this.timeRules = [
      v => !!v || 'Time is required',
      v => this.time_start < this.time_stop || 'Start time must be earlier than end time',
    ];

    this.requiredRulesOrZero = [
      v => (!!v || v === 0) || 'This field is required',
    ]

    this.vehiculeRequiredRules = [
      v => (!!v) || 'Vehicle fields are required',
    ]

    if (this.$route.params.clientid) {
      this.loadClientFromId(this.$route.params.clientid);
    }

    if (this.$route.params.contract_uuid) {
      this.loadContractFromuuid(this.$route.params.contract_uuid);
    }

    if (this.$route.params.finish) {
      this.finish = true;
      this.e1 = 5;
    }
  },

  watch: {
    $data: {
      handler: _.debounce(function (val, oldVal) {
        this.is_new_data = true
        localStorage.setItem("data", JSON.stringify(this.$data))
      }, 1000), // 1000 milliseconds = 1 second
      deep: true
    },
    async searchClient(val) {

      let returned = [];
      if (val) {
        returned = await DataService.searchclient(val);
        this.clientsnames = returned.data.clients;
      }
      else {
        returned = await DataService.getclients();
        this.clientsnames = returned.data.clients;
      }
    },
  },
}
</script>